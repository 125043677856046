<template>
  <div class="activity_home" v-if="hd.id">
    
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in hd.banner" :key="index">
        <img v-lazy="image" width="100%"/>
      </van-swipe-item>
    </van-swipe>
    
    <div class="countDown">
      <van-count-down :time="time">
        <template #default="timeData">
          <div class="text" v-if="hd.state==2">活动已结束</div>
          <label v-else>
            <span>距{{hd.state==0?'开始':'结束'}}：</span>
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">时</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">分</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">秒</span>
          </label>
        </template>
      </van-count-down>
    </div>
    
    <van-row class="user_statistics">
      <van-col :span="goodList.length?6:8" class="item">
        <label>{{hd.total_user}}</label>
        <span>已参与</span>
      </van-col>
      <van-col :span="goodList.length?6:8" class="item">
        <label>{{hd.tj_num}}</label>
        <span>已中奖</span>
      </van-col>
      <van-col span="6" class="item" v-if="goodList.length>0">
        <label>{{hd.seckill_tj_num}}</label>
        <span>已购买</span>
      </van-col>
      <van-col :span="goodList.length?6:8" class="item">
        <label>{{hd.amount}}</label>
        <span>已阅读</span>
      </van-col>
    </van-row>
    
    
    <div class="page_theme">
      <div class="box_1"><span>已有 <em>{{hd.total_user}}</em> 人参与</span></div>
      <div class="box_2">
        <div class="cjnum">
          <span v-if="config.cj_num>0">您还有 {{config.cj_num}} 次抽奖机会！</span>
          <span v-else>您的抽奖次数已用完</span>
        </div>
      </div>
      <div class="box_3">
        <van-row type="flex" class="cardBox">
          <van-col span="8" v-for="(item,key) in cardData" :key="key" class="item">
            <a @click="onClickElement(key)" :style="'background:url('+require('./image/luckybox/box_img_'+item+'.png')+') no-repeat center bottom;'"><figure v-if="element_index==key && choose"></figure></a>
          </van-col>
        </van-row>
      </div>
      <div class="box_4"><a @click="updateData"></a></div>
      <div class="box_5"></div>
  
      <div class="gList" v-if="hd.is_showgood">
        <van-row type="flex" gutter="8">
          <van-col span="8" v-for="item in gList" :key="item.id">
            <div class="img">
              <p><img :src="item.img"/></p>
              <p class="title ellipsis">{{item.title}}</p>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <div class="container">
      <div class="index_box" v-if="hd.total_user>0">
        <div class="h4">已有 <em>{{hd.total_user}}</em> 人参与</div>
        <userList :hdid="hd.id"/>
      </div>
      
      <goodList :type="2" :dataList="goodList" :data="hd" v-if="goodList.length"/>
      
      <div class="index_box" v-if="hd.seckill_tj_num>0">
        <div class="h4">已有 <em>{{hd.seckill_tj_num}}</em> 人购买</div>
        <orderuser :hdid="hd.id" :order_type="1"/>
      </div>
      
      <div class="textContent index_box" v-html="hd.content" v-if="hd.content != null && hd.content != ''"></div>
      
      <div class="mt10"><shopInfo :data="hd.shopInfo"/></div>
    </div>
  </div>
</template>

<script>
  import {getRandom} from '@/utils/util'
  import shopInfo from '@/pages/wx/components/shopInfo'
  import goodList from '@/pages/wx/components/goodList'
  import userList from '@/pages/wx/components/userList'
  import orderuser from '@/pages/wx/components/orderuserList'
  export default {
    props: {
      hd:Object,
      config:Object,
      goodList:Array,
      gList:Array
    },
    components: {goodList,userList,orderuser,shopInfo},
    data() {
      return {
        time:0,
        element_index:undefined,
        cardData:[],
        choose:false,
        timer:null
      }
    },
    activated() {
      this.setBgColor()
    },
    created(){
      this.setBgColor()
      this.updateData()
    },
    mounted() {
      this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();
      this.timer=setInterval(()=>{
        this.chooseme()
      },3000)
      
    },
    methods: {
      setBgColor(){
        if(this.hd.bgcolor==null || this.hd.bgcolor==''){
          document.querySelector('body').setAttribute('style', 'background:linear-gradient(90deg, #df5a6a, #a5081b)')
        }
      },
      updateData(){
        this.cardData=getRandom(1,12,6)
      },
      onClickElement(record){
        this.element_index = record
        this.$emit('onPoint',{type:3})
      },
      chooseme(){
        let key=getRandom(0,5,1)[0]
        this.element_index=key
        this.choose=true
        setTimeout(()=>{
          this.choose=false
        },1200);
      }
    }
  }
</script>
<style lang="less">
  @import "./css/luckybox.less";
</style>
